/* eslint-disable import/no-named-as-default-member */
import XRegExp from 'xregexp'
import { clone } from 'ramda'

export const numberArray = (from: number, to: number) => {
  if (to < from) {
    throw new Error('`from` cannot be greater than `to`')
  }
  return [...Array(to - from + 1).keys()].map((n) => n + from)
}

export const trimString = (str: string, length: number) => {
  return Number(str).toString().padStart(length, '0')
}

const nonSpacingMark = XRegExp('\\p{Mn}')
const urlIgnoreChars = XRegExp('[^\\pL\\d_\\-\\s]')
export const encodeName = (
  name?: string,
  // "strict = true" means to normalize non a-z letter
  strict = true
) => {
  if (!name) return

  let ret = name.toLowerCase()
  ret = XRegExp.replace(ret, urlIgnoreChars, '', 'all')
  ret = ret.replace(/\s/g, '-').replace(/-{2,}/g, '-')
  if (strict) {
    ret = ret.normalize('NFD')
    ret = XRegExp.replace(ret, nonSpacingMark, '', 'all')
    ret = ret.normalize('NFC')
  }
  return ret
}

export function parseToArray<T>(val: T | T[]): NonNullable<T>[] {
  if (val === undefined || val === null) return []
  return (Array.isArray(val) ? val : [val]) as NonNullable<T>[]
}

export enum Action {
  Add = 0,
  Remove = 1,
  Replace = 2,
  Empty = 3,
}

export interface QueryAction {
  key: string
  values?: string | string[]
  action?: Action
}

export const combineQuery = (
  currenctQuery: Record<string, string | (string | null)[]>,
  queries: QueryAction | QueryAction[]
) => {
  const newQuery = clone(currenctQuery)
  const queriesArr = parseToArray(queries)

  queriesArr.forEach((item) => {
    const key = item.key
    let newValue: string[] = []
    const newQueryValues = parseToArray(newQuery[key])
    const valuesArr = parseToArray(item.values)
    const valuesArrSet = new Set(valuesArr)

    switch (item.action) {
      case Action.Remove:
        newValue = newQueryValues.filter((val) => !valuesArrSet.has(val))
        break
      case Action.Replace:
        newValue = valuesArr
        break
      case Action.Empty:
        break
      default:
        newValue = Array.from(new Set(newQueryValues.concat(valuesArr)))
    }

    if (newValue.length) {
      newQuery[key] = newValue.length > 1 ? newValue : newValue?.[0]
    } else {
      delete newQuery[key]
    }
  })

  return newQuery
}

export const highlight = (
  str: string,
  keyword: string,
  bold: boolean = true
) => {
  if (!str || !keyword.trim()) return str
  const escapeRegExp = (text: string) => {
    return text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
  }
  const normalizeQuery = keyword.trim().replace(/\s+/g, ' ')
  const regx = new RegExp(
    String.raw`(${normalizeQuery.split(/\s/).map(escapeRegExp).join('|')})`,
    'ig'
  )
  const fontWeight = bold ? 'font-bold' : 'font-normal'
  return str
    .toString()
    .replace(
      regx,
      (matchedText) => `<span class="${fontWeight}">${matchedText}</span>`
    )
}

export const getValidImage = (imgUrl: string) => {
  if (!imgUrl) return imgUrl

  return imgUrl?.indexOf('//') === 0 ? `https:${imgUrl}` : imgUrl
}

export const getValidColorHex = (str: string) => {
  if (!str) return ''
  return str.charAt(0) === '#' ? str : `#${str}`
}

export const getDecimalLength = (num: number) => {
  const numArr = num.toString().split('.')
  if (numArr.length !== 2) return 0
  return numArr[1].length
}

export const getSizeNum = (str: string) => {
  if (!str) return 0
  const num = Number(str.split(' ')[0])
  return isNaN(num) ? 0 : num
}

export const isString = (val: unknown): val is string => typeof val === 'string'

export const decodeHtml = (str: string) => {
  if (!str) return ''
  return str
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&commat;/g, '@')
    .replace(/&reg;/g, '®')
    .replace(/&copy;/g, '©')
}

export const checkIsVisibleHtml = (str: string) => {
  if (!(str && str.trim())) return false
  return !!str.replace(/<[^>]*>/g, '').trim()
}

export const parseJson = (str: string): Record<string, any> | any[] | null => {
  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

export const sortAndGroupByAlphabet = (data: any, prop: string) => {
  const result = data.reduce((prev: any, current: any) => {
    const alphabet = current[prop]?.[0]?.match(/[a-zA-Z]/)
      ? current[prop]?.[0]
      : '#'
    if (!prev[alphabet]) {
      prev[alphabet] = {
        alphabet,
        record: [current],
      }
    } else {
      prev[alphabet].record.push(current)
    }
    return prev
  }, {})

  return Object.values(result).sort((a: any, b: any) => {
    if (a.alphabet === '#') {
      return 1
    } else {
      return a.alphabet.localeCompare(b.alphabet, 'en', { sensitivity: 'base' })
    }
  })
}

export function getPageUrl(path: string) {
  const words = path.split('/')
  return (!!words.length && words[words.length - 1]) || ''
}

export function getPagePathUrl(path: string) {
  const words = path.split('/')
  words.pop()
  return (!!words.length && words.join('/')) || ''
}

export function getElevatorMenusByUrl(path: string) {
  if (!path) return

  const items = path.split('/')
  return items.map((item, index) => {
    const itemUrl = index > 0 ? items.slice(0, index + 1).join('/') : item
    return {
      label: item,
      url: itemUrl,
    }
  })
}

export const htmlToText = (str: string) => {
  if (!str) return ''
  return str.replace(/<[^>]*>/g, '')
}